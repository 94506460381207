<template>
    <div v-editable="blok" data-test="page" :style="{ backgroundColor: blok.backgroundColor?.color || '#ffffff' }">
        <template v-for="item in blok?.page" :key="item?._uid">
            <component :is="item?.component" :blok="item" />
        </template>
    </div>
</template>

<script setup lang="ts">
import type { IL4Page } from '~/types/components/L4Page';

const { blok } = defineProps<{ blok: IL4Page }>();
const route = useRoute();

route.params.testimonialProductName = blok.Entity?.name;

const { localeProperties } = useI18n();

// Set empty schemaList for useHead()
const schemaList: any[] = [];

// Check to see if schema data exists.
if (process.server && blok.schema?.[0]?.data) {
    // Cycle through each schema to add to schemaList for <Head>
    blok.schema?.forEach((schema: { data: string }) => {
        // Set schema data in prepartion for mutation
        let result: any = schema.data;
        // Check to see if there are any variables within curly bracers '{}' within schema
        const matches: any = schema.data.match(/{(.*?)}/g);
        if (matches?.length) {
            // If matches are found, we must replaceAll that are matched with evalauted variable
            matches.forEach((match: string) => {
                try {
                    result = result.replaceAll(
                        match,
                        Function(`
                            function capitalize(string) {
                                if (string) {
                                    return string.charAt(0).toUpperCase() + string.slice(1);
                                }
                            };
                            const blok = ${JSON.stringify(blok)};
                            return ${match?.substring(1, match?.length - 1)}
                            `)()
                    );
                } catch (e) {
                    console.error('Error in schema evaluation:', e);
                }
            });
        }
        result.replaceAll('null', '').replaceAll('undefined', '');
        if (result) {
            // Remove any empty keys from schema
            let parsed = JSON.parse(result);
            Object.keys(parsed)?.forEach((k) => {
                if (typeof parsed[k] === 'object') {
                    Object.keys(parsed[k]).forEach((k2) => {
                        if (parsed[k] && !parsed[k][k2] && parsed[k][k2] !== undefined) {
                            delete parsed[k][k2];
                            if (k === 'offers' && k2 === 'url') return;
                            delete parsed[k];
                        }
                    });
                } else {
                    return !parsed[k] && parsed[k] !== undefined && delete parsed[k];
                }
            });
            parsed = JSON.stringify(parsed, undefined, 4);
            // Add result to schemaList
            schemaList.push({ type: 'application/ld+json', innerHTML: parsed });
        }
    });
}

useHead({
    htmlAttrs: {
        lang: localeProperties.value.iso ?? '',
    },
    script: schemaList,
});
</script>
