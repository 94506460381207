<template>
    <NuxtLink :to="getL4InternalLink(blok.Link)" :class="`country-link link-flag-${blok.CountryFlag}`">
        {{ blok.Text }}
    </NuxtLink>
</template>

<script setup lang="ts">
import type { IL4CountryLink } from '~/types/components/L4CountryLink';

const { blok } = defineProps<{ blok: IL4CountryLink }>();
</script>

<style lang="postcss" scoped>
@import '~/assets/css/flags.css';
.country-link {
    @apply pl-6 md:pl-8 pr-2 text-bodypurple leading-10 md:min-w-[217px] hover:underline py-0.5;
    font-size: 15px;
    &[class*='link-flag']:before {
        top: 16px;
    }
}
</style>
