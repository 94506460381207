<template>
    <div>
        <main>
            <component :is="story.content.component" v-if="story" :blok="story.content" />
        </main>
    </div>
</template>

<script setup lang="ts">
import { paths } from '~/utils/getLanguage';

const { locale, locales, t } = useI18n();

const route: any = useRoute();
if (route.path.split('/').length > 2) {
    route.params.type = route.path.split('/')[2];
}

// Constant variable for access to Runtime Config
const config = useRuntimeConfig();

// FEEFO API
const { feefoMerchantId } = useFeefo();

const { data: feefoData }: any = await useFetch(
    `https://api.feefo.com/api/20/reviews/summary/product?merchant_identifier=${feefoMerchantId}&since_period=All&unanswered_feedback=include`
);

if (feefoData.value) {
    const feefoSchema = `{
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "${t('reviewSchema.name')}",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": ${feefoData.value?.rating?.rating},
        "bestRating": ${feefoData.value?.rating?.max},
        "reviewCount": ${feefoData.value?.meta?.count}
    }
}`;

    useHead({
        script: [{ type: 'application/ld+json', innerHTML: feefoSchema }],
    });
}

// Need to resolve shared components to allow the storyblok API
// to expose this for us to use.
const resolveRelations: string[] = [
    'L4FooterContainer.footer',
    'L4HeaderContainer.header',
    'L4QuoteFormExtended.airport',
    'L4QuoteFormHotels.airport',
    'L4QuoteFormExtended.keysellingpoints',
    'L4BlockUSP.block',
    'L4CustomerReviews.reviewsBlock',
    'L4QuoteForm.airport',
    'L4Testimonial.review_what',
    'L4ProductHeader.product',
    'L4ProductHeader.airport',
    'L4ProductGrid.products',
    'L4Map.products',
    'L4Map.airport',
    'L4ProductGrid.products',
    'L4TrustedPartners.partners',
    'L4ProductGrid.products',
    'L4ProductMap.products',
    'L4ProductMap.airport',
    'L4AirportParkingTabs.airport',
    'L4ProductPricingTable.carParks',
    'L4CarParkingOptions.products',
    'L4CarParkingOptions.airport',
    'L4ProductContent.product',
    'L4TopRatedCarParks.carParks',
    'L4TransferOptionsContainer.options_component',
    'L4EmailSignupNew.signup',
];

const routeParts = (route.path?.startsWith('/') ? route.path.slice(1) : route.path).split('/');
const routeTypeSlug = routeParts[1];

const isTransferPage = Object.values(paths.transfers)
    .filter((slug) => slug !== '')
    .some((slug) => slug === routeTypeSlug);
const isParkingPage = Object.values(paths.parking)
    .filter((slug) => slug !== '')
    .some((slug) => slug === routeTypeSlug);
const isPortParkingPage = Object.values(paths['port-parking'])
    .filter((slug) => slug !== '')
    .some((slug) => slug === routeTypeSlug);
const isTrainStationParkingPage = Object.values(paths['train-station-parking'])
    .filter((slug) => slug !== '')
    .some((slug) => slug === routeTypeSlug);

// Perform request to Storyblok to retrieve content
const story: any = await useAsyncStoryblok(getSbLocation(), {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: getLanguage(),
    resolve_relations: resolveRelations,
}).then((data) => {
    const config = useRuntimeConfig();
    setSeoMeta(data.value?.content.seo, config?.public, route);

    return data;
});

function getStoryblokBlockedRoutes() {
    if (config?.public.STORYBLOK_ROUTE_BLOCKED) {
        const blocked = config?.public.STORYBLOK_ROUTE_BLOCKED.split(', ');
        return blocked.some((block) => route.path.includes(block));
    } else {
        return false;
    }
}

function getSbLocation() {
    let routePath = route.path?.startsWith('/') ? route.path.slice(1) : route.path;
    const routeParts = routePath.split('/');

    if (isTransferPage) {
        const transferSlug = getProductTypeSlug(getLanguageForLocale(getLanguage()), 'airport-transfers');

        if (routeParts.length === 3) {
            let location = routeParts[0];
            let direction = routeParts[2] || '';
            if (routeParts[2] && routeParts[2].length === 2) {
                location = routeParts[2];
                direction = '';
            }
            routePath = `${location}/airport-transfers/${direction}`;
        } else if (routeParts.length === 2) {
            routePath = `${routeParts[0]}/airport-transfers`;
        }

        route.params.type = transferSlug;
    } else if (isParkingPage || isPortParkingPage || isTrainStationParkingPage) {
        let pageTypeSlug: string;
        if (isParkingPage) {
            pageTypeSlug = 'airport-parking';
        } else if (isPortParkingPage) {
            pageTypeSlug = 'port-parking';
        } else if (isTrainStationParkingPage) {
            pageTypeSlug = 'train-station-parking';
        }
        // replace localized page type slug with slug we have in SB
        routePath = routeParts.map((part: string, index: number) => (index === 1 ? pageTypeSlug : part)).join('/');
    }

    const location = routePath.split('/')[0];
    routePath = location.length > 2 ? routePath.replace(location, location.split('-')[0]) : routePath;

    return `/${config?.public.STORYBLOK_FOLDER}/pages/${routePath}`;
}

function getLanguage() {
    const routePath = route.path?.startsWith('/') ? route.path.slice(1) : route.path;
    const location = routePath.split('/')[0];

    return location.length > 2
        ? location.split('-')[1]
        : locales.value.filter((e) => e.code === locale.value)[0].storyblok;
}

// Send user to error page if no story found
if (getStoryblokBlockedRoutes() || !story.value) {
    throw createError({
        statusCode: 404,
        statusMessage: 'Page Not Found',
        fatal: true,
    });
}

if (!process.server) {
    // Allow use of Storyblok Bridge (Visual Editor)
    useStoryblokBridge(story.id, (evStory: any) => (story.value = evStory), { resolveRelations });
}
</script>
