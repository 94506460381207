<template>
    <footer v-editable="blok" :class="['footer', showLinks && 'footer--airports']" data-test="NewL4FooterContainer">
        <div class="footer__container md:container">
            <!-- Begin: Footer - Top -->
            <div class="footer__top">
                <div class="footer__top-logo">
                    <NuxtLink :to="`${domain}/`" aria-label="logo" class="logo looking4" external />
                </div>
                <button class="footer__top-trust" aria-label="footer-trust-button" @click="() => (showModal = true)">
                    <div class="logo trustwave" />
                    <div class="logo secure" />
                    <div class="logo thawte" />
                </button>
                <TrustModal v-if="showModal" @close="() => (showModal = false)" />
                <template v-for="menuItem in blok.footer.content.bottom_menu">
                    <TrustModalPaymentInfo
                        v-if="showModalPayment && menuItem.TrustModalContent?.length"
                        :key="menuItem._uid"
                        :content="menuItem.TrustModalContent[0]"
                        @close="() => (showModalPayment = false)"
                    />
                </template>
            </div>
            <!-- End: Footer - Top -->

            <!-- Begin: Footer - Body -->
            <div v-if="showLinks" class="footer__body">
                <div class="footer__body--top-item">
                    <NuxtLink :to="getL4InternalLink(footer.top_menu[0].url)" external>
                        {{ footer.top_menu[0].text }}
                    </NuxtLink>
                </div>
                <div class="footer-airports">
                    <template v-for="item in footer.top_menu[0].children" :key="item._uid">
                        <NuxtLink class="footer-airports__link" :to="getL4InternalLink(item.url)" external>
                            {{ item.text }}
                        </NuxtLink>
                    </template>
                </div>
            </div>
            <!-- End: Footer - Body -->

            <!-- Footer - Socials -->
            <div v-if="socialLinks.length" class="footer__socials">
                <LazySocialLinks :links="socialLinks" />
            </div>
            <!-- End: Footer - Socials -->

            <!-- Begin: Footer - Bottom -->
            <div class="footer__bottom">
                <!-- Footer - Bottom - Anciliary Links -->
                <div class="footer__bottom-menu">
                    <ul class="footer-menu">
                        <li
                            v-if="getL4InternalLink(footerLinks.termsLink) && footer.terms_label"
                            class="footer-menu__item"
                        >
                            <NuxtLink class="footer-menu__link" :to="getL4InternalLink(footerLinks.termsLink)" external>
                                {{ footer.terms_label }}
                            </NuxtLink>
                        </li>
                        <li v-if="getL4InternalLink(footerLinks.faqLink)" class="footer-menu__item">
                            <NuxtLink class="footer-menu__link" :to="getL4InternalLink(footerLinks.faqLink)" external>
                                {{ footer.faq_label }}
                            </NuxtLink>
                        </li>
                        <li v-if="getL4InternalLink(footerLinks.helpLink)" class="footer-menu__item">
                            <NuxtLink class="footer-menu__link" :to="getL4InternalLink(footerLinks.helpLink)" external>
                                {{ footer.help_label }}
                            </NuxtLink>
                        </li>
                        <template v-for="item in footer.bottom_menu" :key="item._uid">
                            <li v-if="item.TrustModalContent?.length" class="footer-menu__item">
                                <a class="footer-menu__link" @click="() => (showModalPayment = true)">
                                    {{ item.text }}
                                </a>
                            </li>
                            <li v-else class="footer-menu__item">
                                <NuxtLink class="footer-menu__link" :to="getL4InternalLink(item.url)" external>
                                    {{ item.text }}
                                </NuxtLink>
                            </li>
                        </template>
                        <li class="footer-menu__item footer-menu__item--countries">
                            <CountrySelect :type="pageType" :lang="locale" :multi-languages="footer.multi_languages" />
                        </li>
                    </ul>
                </div>

                <!-- Footer - Bottom - Copyright -->
                <div class="footer__copyright">
                    <p>{{ footer.copyright_line_1 }}</p>
                    <p>© {{ new Date().getFullYear() }} {{ footer.copyright_line_2 }}</p>
                </div>
            </div>
            <!-- End: Footer - Bottom -->
        </div>
    </footer>
</template>

<script setup lang="ts">
import footerData from '~/src/footerData.json';
import { getL4InternalLink } from '~/utils/helpers';
import type { IL4FooterContainer } from '~/types/components/L4FooterContainer';

const { blok } = defineProps<{ blok: IL4FooterContainer }>();

const { category } = toRefs(blok);
const { content: footer } = toRefs(blok.footer);

const route = useRoute();
const domain = useRuntimeConfig().public.SITE_URL;
let pageType: string;
if (route.params.slug) {
    [pageType] = route.params.slug;
} else if (route.params.type) {
    pageType = route.params.type as string;
}

const localePath = useLocalePath();
const { locale } = useI18n();

// Show airport links only if category selected and it's page for selected category
const showLinks = computed(() => {
    if (!category) return false;
    const pagesSlugs = footerData.categorySlugs[category.value];
    if (!pagesSlugs || !pageType) return false;
    return footer.value.top_menu[0].children.length && pagesSlugs.includes(pageType);
});

// Modal
const showModal = ref<boolean>(false);
const showModalPayment = ref<boolean>(false);

// Footer meny links - different for transfers page and all other pages
const footerLinks = computed(() => {
    const transfersLinks = footerData.categorySlugs.transfer;
    const isTransferType = transfersLinks.includes(pageType as string);
    return isTransferType
        ? {
              termsLink: footer.value.transfers_terms_link,
              faqLink: footer.value.transfers_faq_link,
              helpLink: footer.value.transfers_help_link,
          }
        : {
              termsLink: footer.value.parking_terms_link,
              faqLink: footer.value.parking_faq_link,
              helpLink: footer.value.parking_help_link,
          };
});

const socialLinks = computed(() => {
    if (!footer?.value?.social_links) return [];
    return footer.value.social_links.map((link) => ({
        class: link,
        link: footer.value[`${link}_link`],
    }));
});
</script>

<style lang="postcss" scoped>
@import 'assets/css/logos.css';

.footer {
    @apply bg-[#393939] text-white;

    &__container {
        @apply p-7 md:px-2 lg:px-0 flex flex-col;
    }

    &__top {
        @apply flex flex-col sm:flex-row justify-between sm:items-center mb-10;

        .footer--airports & {
            @apply mb-4;
        }

        &-trust {
            @apply flex md:justify-end items-center py-4;
        }
    }

    &__body {
        @apply py-4 lg:py-6 border-y border-[#656565] mb-6;

        &--top-item {
            @apply inline-block text-xl underline mb-4;

            &:hover {
                @apply no-underline;
            }
        }
    }

    &__socials {
        @apply w-full order-5 md:order-none mb-2;
    }

    &__bottom {
        @apply flex flex-col items-start md:flex-row justify-between md:items-end pb-4 text-sm;

        &-menu {
            @apply mb-7 md:mb-0 md:max-w-[460px];
        }
    }

    &__copyright {
        @apply max-w-[315px] md:text-right;
    }
}

.footer-airports {
    @apply sm:columns-2 tablet:columns-3 lg:columns-4 text-sm;

    &__link {
        @apply block text-base pr-2 py-1 mb-2 lg:py-1.5 lg:mb-0;

        &:hover {
            @apply underline;
        }
    }
}

.footer-menu {
    @apply lg:flex items-end flex-wrap -mb-1 list-none;

    &__item {
        @apply block pr-2 mr-2 py-0 relative mb-1;

        &:first-child {
            @apply pl-0;
        }

        &:last-child {
            &::after {
                @apply hidden;
            }
        }

        &:after {
            content: '';
            @apply hidden lg:block absolute right-0 top-1 bottom-1 w-[1px] bg-white;
        }

        &--countries {
            @apply -ml-2;
        }
    }

    &__link {
        @apply cursor-pointer text-sm py-1 mb-1 lg:py-1 lg:mb-0 hover:underline inline-block w-full leading-none;
    }
}
</style>
