import payload_plugin_WngsupBcIk from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_wljcdoKvZT from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Mh1bPe7akv from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_54foKnhaGD from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_OCOsh9hjEJ from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.9_jiti@2.4_53mh3m6breszxonmbckyoawwva/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_wKYeM8ZQ1q from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HuCwQOiAAc from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_KmYHwLPV33 from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2FROhYqY8N from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_bIPRCMVWqm from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/.nuxt/components.plugin.mjs";
import prefetch_client_Uinis8cA9q from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_FqHulbr44N from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.9_jiti@2.4.2_ter_eaov4b3sixqv3dc757yl7qaqxq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_plugin_57HgTwgZ1Q from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_5clynZCQYE from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_IBBiydfrnF from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.31.0_typescript@5_zbgwqseajsrwe6l2y6dpohv7je/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_NDUm4V2BnB from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_vaXKGi9wOn from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_2hii42QBJd from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  payload_plugin_WngsupBcIk,
  revive_payload_client_wljcdoKvZT,
  unhead_Mh1bPe7akv,
  router_54foKnhaGD,
  _0_siteConfig_OCOsh9hjEJ,
  payload_client_wKYeM8ZQ1q,
  navigation_repaint_client_HuCwQOiAAc,
  check_outdated_build_client_KmYHwLPV33,
  chunk_reload_client_2FROhYqY8N,
  plugin_vue3_bIPRCMVWqm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Uinis8cA9q,
  plugin_OHU0vggoM9,
  plugin_FqHulbr44N,
  nuxt_plugin_57HgTwgZ1Q,
  plugin_5clynZCQYE,
  plugin_IBBiydfrnF,
  switch_locale_path_ssr_NDUm4V2BnB,
  i18n_vaXKGi9wOn,
  base64_2hii42QBJd
]