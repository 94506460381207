<template>
    <ul v-if="links.length" class="social-links">
        <li v-for="(social, index) in links" :key="index" class="social-links__item">
            <a :href="social.link" aria-label="social" class="logo" :class="social.class" />
        </li>
    </ul>
</template>

<script setup lang="ts">
const { links } = defineProps<{
    links: {
        link: string;
        class: string;
    }[];
}>();
</script>

<style lang="postcss" scoped>
@import 'assets/css/logos.css';

.social-links {
    @apply flex items-center gap-1.5;
}
</style>
